export const VerifiedIcon = () => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.500002 6.85806C0.500122 3.19778 3.76504 0.333313 7.70827 0.333313C11.6551 0.333313 14.8665 3.18438 14.8665 6.84159C14.8665 10.5024 11.5928 13.3665 7.65827 13.3665H7.65821C6.94956 13.3665 6.24399 13.2734 5.55955 13.0897C5.27063 13.0121 5.09926 12.7151 5.17681 12.4261C5.25435 12.1372 5.55143 11.9658 5.84036 12.0434C6.43324 12.2025 7.04442 12.2831 7.65827 12.2832C11.107 12.2832 13.7832 9.79741 13.7832 6.84159C13.7832 3.8822 11.1614 1.41665 7.70827 1.41665C4.25165 1.41665 1.58334 3.90203 1.58334 6.85826L1.58333 6.8587C1.58248 7.89636 1.90829 8.90796 2.51458 9.75007C2.52462 9.76401 2.53398 9.77842 2.54265 9.79325C2.84758 10.315 2.99018 10.9157 2.95226 11.5189C2.92644 11.9295 2.81776 12.3285 2.63493 12.6927C2.64253 12.6909 2.6501 12.6891 2.65764 12.6873C2.87286 12.6364 3.06561 12.5892 3.20756 12.5431C3.49206 12.4506 3.79766 12.6063 3.89012 12.8908C3.98259 13.1753 3.82691 13.4809 3.5424 13.5733C3.35102 13.6355 3.11461 13.6925 2.90692 13.7416C2.88333 13.7472 2.86012 13.7526 2.83731 13.758C2.64649 13.803 2.48354 13.8415 2.36268 13.8774C2.00409 13.984 1.61258 13.8704 1.407 13.5324C1.21759 13.221 1.26511 12.85 1.4378 12.5674C1.44778 12.5511 1.45861 12.5353 1.47026 12.5201C1.70711 12.2114 1.84664 11.8392 1.87106 11.4509C1.89498 11.0704 1.80733 10.6914 1.61912 10.3603C0.890418 9.33822 0.499019 8.11379 0.500002 6.85806ZM0.500002 6.85806C0.500002 6.85797 0.500002 6.85789 0.500002 6.85781L1.04167 6.85826H0.500002C0.500002 6.85819 0.500002 6.85812 0.500002 6.85806Z"
            />
            <path d="M4.74153 7.69085C4.8918 7.67951 5.03551 7.6246 5.15506 7.53285C5.2746 7.44109 5.36481 7.31647 5.41462 7.17424C5.46444 7.03202 5.47171 6.87835 5.43554 6.73205C5.39937 6.58576 5.32133 6.45318 5.21098 6.35055C5.10062 6.24793 4.96274 6.1797 4.81421 6.15422C4.66568 6.12875 4.51294 6.14713 4.3747 6.20711C4.23645 6.2671 4.1187 6.3661 4.03584 6.49198C3.95299 6.61786 3.90863 6.76516 3.90821 6.91586C3.9079 7.02273 3.92971 7.12852 3.97225 7.22656C4.01479 7.3246 4.07716 7.41278 4.15542 7.48557C4.23368 7.55835 4.32615 7.61416 4.42701 7.64949C4.52788 7.68481 4.63496 7.6989 4.74153 7.69085V7.69085Z" />
            <path d="M7.64218 7.6922C7.79546 7.6922 7.9453 7.64675 8.07274 7.56159C8.20019 7.47643 8.29952 7.35539 8.35818 7.21378C8.41684 7.07217 8.43218 6.91635 8.40228 6.76601C8.37238 6.61568 8.29857 6.47759 8.19018 6.3692C8.0818 6.26082 7.94371 6.18701 7.79337 6.1571C7.64304 6.1272 7.48721 6.14255 7.3456 6.20121C7.20399 6.25986 7.08295 6.3592 6.9978 6.48664C6.91264 6.61409 6.86719 6.76393 6.86719 6.91721C6.86719 7.12275 6.94884 7.31987 7.09418 7.46521C7.23952 7.61055 7.43664 7.6922 7.64218 7.6922V7.6922Z" />
            <path d="M10.5746 7.69133C10.6826 7.70257 10.7917 7.691 10.8949 7.65739C10.9981 7.62377 11.0931 7.56885 11.1737 7.49618C11.2544 7.42351 11.3188 7.33472 11.3629 7.23555C11.4071 7.13638 11.4299 7.02904 11.4299 6.9205C11.4299 6.81196 11.4071 6.70463 11.3629 6.60546C11.3188 6.50629 11.2544 6.41749 11.1737 6.34482C11.0931 6.27215 10.9981 6.21723 10.8949 6.18361C10.7917 6.15 10.6826 6.13843 10.5746 6.14967C10.384 6.16952 10.2075 6.25929 10.0792 6.40165C9.95089 6.54401 9.87988 6.72885 9.87988 6.9205C9.87988 7.11215 9.95089 7.297 10.0792 7.43935C10.2075 7.58171 10.384 7.67148 10.5746 7.69133Z" />
        </svg>
    );
};
