import { useCallback, useState } from 'react';
import { getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { talkToUsInstance } from '@/common/service/api/Axios';
import { getBodyParamsByQuestion } from './getBodyParams';
import { TQuestionTypes } from '../domain/TalkToUs.types';
import { trackSubscribeCRS } from '@/common/app/utils/iterable/requests';
import { isProd } from '@/common/app/constants/envs';

const TIMEZONE_CENTRAL_TIME = 'America/Chicago';
const WORKING_HOURS = [8, 16];

export type TTalkToUsRequest = {
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
    questionType: string;
};

export const useTalkToUsAPI = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState({ message: '' });

    const request = async (args: TTalkToUsRequest) => {
        setIsLoading(true);
        setIsSuccess(false);
        setIsError(false);
        setError({ message: '' });

        const params = getBodyParamsByQuestion(args.questionType);
        const pathname = window.location.href;

        const body = {
            ...params,
            DestinationPhoneNumber: args.phone,
            Attributes: {
                FirstName: args.firstName,
                LastName: args.lastName,
                Email: args.email,
                questionType: args.questionType,
                InitPage: pathname,
                gclid: localStorage.getItem('gclid'), // TODO: use the right helper for this.
            },
        };

        const response = await talkToUsInstance.post('exec_public/startOutboundVoiceContact', body);

        // TODO: handle response
        // TODO: Add Analytics layer.
        // TODO: Update the user information to local Storage.

        if (!response) {
            setIsLoading(false);
            setIsError(true);
            setError({ message: 'Something went wrong' });
            return;
        }
        // Api Call with Async/Await

        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);

        return { message: 'Success', isLoading, isSuccess, isError, error };
    };

    return { request };
};

export const useTalkToUs = () => {
    const { request } = useTalkToUsAPI();
    const [isOpen, setIsOpen] = useState(false);
    const [questionType, setQuestionType] = useState<TQuestionTypes>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState({ message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    const onChangeQuestion = useCallback(
        (value: TQuestionTypes) => setQuestionType(value),
        [setQuestionType]
    );

    const isValidTime = () => {
        const ZonedTime = utcToZonedTime(new Date(), TIMEZONE_CENTRAL_TIME); // Central Time
        return getHours(ZonedTime) >= WORKING_HOURS[0] && getHours(ZonedTime) < WORKING_HOURS[1];
    };

    const onSubmit = async (args: TTalkToUsRequest) => {
        if (isValidTime()) {
            setIsLoading(true);
            const result = await request(args);

            if (!result || result?.isError) {
                setIsSuccess(false);
                setIsLoading(false);
                setIsError(true);
                return false;
            }

            setIsSuccess(true);
            setIsLoading(false);
            setError(result.error);

            try {
                trackSubscribeCRS(
                    'subscribed',
                    args.email,
                    {
                        firstName: args.firstName,
                        lastName: args.lastName,
                        phone: args.phone,
                    },
                    isProd ? 'sqqhoo' : 'sqqhvz'
                );
            } catch (error) {
                console.log('error', error);
            }
            return true;
        }

        return false;
    };

    return {
        isOpen,
        questionType,
        request,
        open,
        close,
        onChangeQuestion,
        onSubmit,
        isValidTime,
        isSuccess,
        isError,
        error,
        isLoading,
    };
};
