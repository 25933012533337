import { ReactElement } from 'react';
import * as S from './SliderDots.styles';
import { TSliderDots } from './SliderDots.domain';

export const SliderDots = ({ onClick, isActive, size, desktop }: TSliderDots): ReactElement => {
    return (
        <div onClick={onClick} style={{ height: 'fit-content' }}>
            <S.SliderDots isActive={isActive} size={size} desktop={desktop} />
        </div>
    );
};
