import { TStyleOverlay } from '@/common/ui/modals/Modal/Modal.types';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { TTheme } from '@/styles/Themes/Theme.domain';
import styled, { ThemedStyledProps } from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import { FlexPresets } from '@/styles/Themes/Presets';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { Instagram } from '../images/icons/Instagram';
import { Facebook } from '../images/icons/Facebook';

// interface ICloseIcon extends DefaultTheme {
//     onClick: () => void;
// }

export const Overlay = styled.div<ThemedStyledProps<TStyleOverlay, TTheme>>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
`;

// export const Modal = styled.div<ThemeProps<TTheme>>`
//     background-color: ${cssVars.bg_white_blueDark};
//     box-shadow: 0px 10.89px 43.36px rgba(17, 15, 15, 0.53);
//     border-radius: ${radius.small};

//     padding: 22px;

//     max-width: 640px;
//     min-width: 340px;

//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// `;

// export const ModalTop = styled.div<ThemeProps<TTheme>>`
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 8px;
// `;

// export const ModalBody = styled.div`
//     margin-top: 20px;
// `;

// export const Logo = styled(Logotype)`
//     width: 107px;
//     cursor: pointer;

//     ${Screen.ipad} {
//         width: 170px;
//         height: 70px;
//     }
// `;

export const FormHeader = styled.div`
    text-align: center;
`;

// export const FormList = styled.ul`
//     margin-top: 22px;
//     color: ${cssVars.fg_gray2_gray3};
//     list-style-type: none;
//     padding-left: 15px;
//     ${Screen.ipad} {
//         padding: 10px 0 10px 20px;
//     }
//     ${Screen.desktop} {
//         padding-left: 30px;
//     }
// `;

// export const FormListItem = styled.li`
//     ${Text.Full.large.semibold};
//     color: ${cssVars.fg_gray1_white};
// `;

export const Description = styled.div`
    font-size: 12px;
    color: ${cssVars.fg_gray_light};
`;

export const FormInput = styled.input`
    ${FlexPresets.flexRowCenter};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    background: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};
    padding: 0px 25px 0px 20px;
    width: 100%;
    height: 40px;
    outline: none;
    ${Text.Full.large.medium};

    ${Screen.desktop} {
        height: 50px;
    }
`;

export const Form = styled.div`
    ${FlexPresets.flexColCenter};
    margin: auto;
    padding: 1em 0;
    gap: 15px;
    max-width: 300px;

    @media (min-width: 768px) {
        margin-top: 1rem;
    }
`;

export const CloseButtonWithIcon = styled(CloseIcon)``;

// export const CloseButton = styled.div<ICloseIcon>`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 36px;
//     height: 36px;
//     background: ${cssVars.bg_ash_normal};
//     border-radius: ${radius.huge};
//     position: absolute;
//     right: 0px;
//     top: 0px;
//     cursor: pointer;

//     ${Screen.ipad} {
//         width: 40px;
//         height: 40px;
//     }

//     ${Screen.desktop} {
//         width: 36px;
//         height: 36px;
//     }

//     &:hover {
//         background: ${cssVars.bg_white_dark3};
//         box-shadow: ${cssVars.shadow_card_default};
//     }
// `;
export const Button = styled.button`
    color: ${Palette.green.normal};
    background-color: transparent;
    border: ${borders.small} ${Palette.green.normal};
    border-radius: ${radius.large};
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${Text.Mobile.medium.medium}

    :active,
    :hover {
        border: ${borders.small} ${Palette.green.light};
    }

    ${Screen.ipad} {
        height: 55px;
        ${Text.Ipad.medium.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.medium}
        width: 50%;
        height: 50px;
    }
`;

export const SuccessInstructions = styled.h4`
    ${Text.Full.heading4}
    color: ${cssVars.fg_gray1_white};
    text-align: center;
    margin-top: 20px;
`;

export const SocialIconsWrapper = styled.div`
    display: flex;
    ${Text.Full.large.semibold};
    color: ${cssVars.fg_gray2_gray3};
    flex-direction: row;
    justify-content: center;
    gap: 20px;
`;

export const HugeSocialIcons = styled.div`
    display: flex;
    align-items: center;
    background-color: ${cssVars.bg_white_dark3};
    width: 36px;
    height: 36px;
    border-radius: ${radius.small};
    margin: 5px 0;
    cursor: pointer;

    ${Screen.ipad} {
        height: 36px;
        margin: 6px 0;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
        margin: 0;
    }
`;

export const FacebookCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #5572b0 0%, #405c98 48.23%, #22438a 100%);
    border-radius: 36px;
`;

export const InstagramCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        #4c61d3 0%,
        #893fb8 19.06%,
        #c32994 40.42%,
        #ea2a67 56.25%,
        #f05631 73.75%,
        #f7a74b 87.81%,
        #fde174 98.44%
    );
    border-radius: 36px;
`;

export const InstagramIcon = styled(Instagram)`
    ${Screen.ipad} {
        width: 24px;
        height: 24px;
    }
`;

export const FacebookIcon = styled(Facebook)`
    ${Screen.ipad} {
        width: 24px;
        height: 24px;
    }
`;

// export const PopupOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// `;

// export const Modal = styled.div<ThemeProps<TTheme>>`
//     background-color: ${cssVars.bg_white_blueDark};
//     box-shadow: 0px 10.89px 43.36px rgba(17, 15, 15, 0.53);
//     border-radius: ${radius.small};

//     padding: 22px;

//     max-width: 640px;
//     min-width: 340px;

//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// `;

export const Modal = styled.div`
    background-color: ${cssVars.bg_white_blueDark};
    box-shadow: 0px 10.89px 43.36px rgba(17, 15, 15, 0.53);
    border-radius: ${radius.small};
    overflow: hidden;
    width: 98%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        max-width: 700px;
    }
`;

export const ModalBody = styled.div`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    flex: 1;
    @media (min-width: 768px) {
        padding: 2.5rem;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background: ${cssVars.bg_ash_normal};
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @media (min-width: 768px) {
        right: 1rem;
        top: 1rem;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;

export const Title = styled.h2`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5rem;
    color: ${cssVars.fg_gray1_white};

    @media (min-width: 768px) {
        font-size: 18px;
    }
`;

export const PercentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Percent = styled.div`
    color: #00bfb3;
    font-weight: 600;
    font-size: 44px;
    line-height: 1.5;
    margin: 0 16px;
    @media (min-width: 768px) {
        font-size: 56px;
    }
`;
export const PercentDecoration = styled.div`
    position: relative;
    width: 10px;
    height: 2px;
    border-radius: 2px;
    background: ${Palette.gradient.light};
    &:before,
    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 2px;
        border-radius: 2px;
        background: ${Palette.gradient.light};
        position: absolute;
    }
    &:first-child {
        &:before {
            top: -12px;
            transform: rotate(35deg);
        }
        &:after {
            bottom: -12px;
            transform: rotate(-35deg);
        }
    }

    &:last-child {
        &:before {
            top: -12px;
            transform: rotate(-35deg);
        }
        &:after {
            bottom: -12px;
            transform: rotate(35deg);
        }
    }
`;
export const Subtitle = styled.div`
    color: #00bfb3;
    text-align: center;
    margin-bottom: 1rem;
    text-align: center;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    background: ${cssVars.bg_promo_subtitle};
    border-radius: 15px;
    @media (min-width: 768px) {
        font-size: 16px;
    }
`;

export const Img = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 40% 0%;

    @media (min-width: 768px) {
        object-position: 40% 50%;
    }
`;

// export const Description = styled.p`
//   color: #666;
//   text-align: center;
//   font-size: 0.9rem;
//   margin-bottom: 1.5rem;
// `;

// export const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// `;

// export const Input = styled.input`
//     padding: 0.75rem;
//     border: 1px solid #e5e7eb;
//     border-radius: 25px;
//     width: 100%;

//     &::placeholder {
//         color: #9ca3af;
//     }
// `;

// export const SubmitButton = styled.button`
//     background: #00bfb3;
//     color: white;
//     padding: 0.75rem;
//     border: none;
//     border-radius: 25px;
//     cursor: pointer;
//     font-weight: 500;
//     transition: background-color 0.2s;

//     &:hover {
//         background: #00a89d;
//     }
// `;

export const ImageSection = styled.div`
    position: relative;
    height: 230px;

    @media (min-width: 768px) {
        width: 50%;
        height: auto;
        min-height: 400px;
    }
`;

export const Features = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    z-index: 2;
    @media (min-width: 768px) {
        top: auto;
        transform: translateY(0);
        bottom: 2.5rem;
        gap: 14px;
    }
`;

export const FeatureTag = styled.div`
    background-color: ${cssVars.bg_white_blueDark};
    color: ${cssVars.fg_gray1_white};
    padding: 4px 16px;
    border-radius: 15px 0 0 15px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    span {
        margin-right: 2px;
    }
    span,
    svg {
        display: block;
    }

    @media (min-width: 768px) {
        font-size: 14px;
        span {
            margin-right: 4px;
        }
    }
`;
