import { getLS, LocalStorageTypes, setLS } from '@/common/service/storage';
import { useEffect, useState } from 'react';
import * as S from './DiscountWidget.styles';
import { DiscountWidgetForm } from './DiscountWidgetForm';
import { DiscountWidgetSuccess } from './DiscountWidgetSuccess';
import { LowPrice } from '../images/icons/LowPrice';
import { Trusted } from '../images/icons/Trusted';
import { VerifiedIcon } from '../images/icons/VerifiedIcon';
import { FreeCancelation } from '../images/icons/FreeCancelation';
import { LiveSupport } from '../images/icons/LiveSupport';

//const getOpenCount = () => +(getLS(LocalStorageTypes.LS_DISCOUNT_WIDGET) || 0);

// const setOpenCount = () => {
//     const currentCount = getOpenCount();
//     setLS(LocalStorageTypes.LS_DISCOUNT_WIDGET, +currentCount + 1);
// };

const checkAndRunWidget = (fn: (a: boolean) => void) => {
    const CHECK_INTERVAL = 1000 * 60 * 60 * 15;
    const lastCheck = getLS(LocalStorageTypes.LS_DISCOUNT_WIDGET);
    const submitted = getLS(LocalStorageTypes.LS_DISCOUNT_WIDGET_SUBMITTED);
    const now = Date.now();

    if ((!lastCheck || now - Number(lastCheck) > CHECK_INTERVAL) && !submitted) {
        setTimeout(() => {
            fn(true);
            setLS(LocalStorageTypes.LS_DISCOUNT_WIDGET, now);
        }, 10000);
    }
};

export const DiscountWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        checkAndRunWidget(setIsOpen);
        // if (getOpenCount() >= 1) {
        //     return;
        // }

        // setTimeout(() => {
        //     setIsOpen(true);
        //     setOpenCount();
        // }, 10000);
    }, []);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <S.Overlay open={isOpen} onClick={() => setIsOpen(false)}>
            <S.Modal onClick={(e) => e.stopPropagation()}>
                <S.CloseButton onClick={() => onClose()}>
                    <S.CloseButtonWithIcon />
                </S.CloseButton>
                {/* <S.ModalTop>
                    <S.Logo /> 
                    <S.CloseButton onClick={() => onClose()}>
                        <S.CloseButtonWithIcon />
                    </S.CloseButton>
                </S.ModalTop> */}
                <S.ModalBody>
                    {submitted ? (
                        <DiscountWidgetSuccess />
                    ) : (
                        <DiscountWidgetForm setSubmitted={setSubmitted} />
                    )}
                </S.ModalBody>
                <S.ImageSection>
                    <S.Img src="/image/promo.jpg" alt="Tripshock Promo" />
                    <S.Features>
                        <S.FeatureTag>
                            <span>
                                <LowPrice />
                            </span>
                            Low Price Guarantee
                        </S.FeatureTag>
                        <S.FeatureTag>
                            <span>
                                <VerifiedIcon />
                            </span>
                            Verified Traveler Reviews
                        </S.FeatureTag>
                        <S.FeatureTag>
                            <span>
                                <LiveSupport />
                            </span>
                            7 Day Live Support
                        </S.FeatureTag>
                        <S.FeatureTag>
                            <span>
                                <FreeCancelation />
                            </span>
                            FREE Cancellation
                        </S.FeatureTag>
                        <S.FeatureTag>
                            <span>
                                <Trusted />
                            </span>
                            Trusted Since 2009
                        </S.FeatureTag>
                    </S.Features>
                </S.ImageSection>
            </S.Modal>
        </S.Overlay>
    );
};
