export const LiveSupport = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.333252 7.16665C0.333252 3.3927 3.39264 0.333313 7.16659 0.333313C10.9405 0.333313 13.9999 3.3927 13.9999 7.16665V11.6805C13.9999 12.9615 12.9615 14 11.6805 14C10.3995 14 9.36103 12.9615 9.36103 11.6805V10.2916C9.36103 9.01065 10.3995 7.9722 11.6805 7.9722C12.1001 7.9722 12.4937 8.08363 12.8333 8.2785V7.16665C12.8333 4.03703 10.2962 1.49998 7.16659 1.49998C4.03697 1.49998 1.49992 4.03703 1.49992 7.16665V8.2785C1.8395 8.08363 2.23309 7.9722 2.6527 7.9722C3.93369 7.9722 4.97214 9.01065 4.97214 10.2916V11.6805C4.97214 12.9615 3.93369 14 2.6527 14C1.3717 14 0.333252 12.9615 0.333252 11.6805V7.16665ZM1.49992 11.6805C1.49992 12.3172 2.01604 12.8333 2.6527 12.8333C3.28936 12.8333 3.80547 12.3172 3.80547 11.6805V10.2916C3.80547 9.65498 3.28936 9.13887 2.6527 9.13887C2.01604 9.13887 1.49992 9.65498 1.49992 10.2916V11.6805ZM12.8333 10.2916C12.8333 9.65498 12.3171 9.13887 11.6805 9.13887C11.0438 9.13887 10.5277 9.65498 10.5277 10.2916V11.6805C10.5277 12.3172 11.0438 12.8333 11.6805 12.8333C12.3171 12.8333 12.8333 12.3172 12.8333 11.6805V10.2916Z"
            />
        </svg>
    );
};
