import { THeroImageData, THeroDataProps } from '../domain/Layout.domain';
import HomePageLight from '@/shared/images/HeroImages/HomePageLight.jpg';
import HomePageDark from '@/shared/images/HeroImages/HomePageDark.jpg';
import Blog from '@/shared/images/HeroImages/Blog.jpg';
import Partners from '@/shared/images/HeroImages/Partners.jpg';
import Landmark from '@/shared/images/HeroImages/Landmark.jpg';
import { SubtitleH1 } from '../ui/HeroImage/HeroImage.styles';

export const getHeroImageData = ({
    pathname,
    destinationName,
    subTitle,
    images,
    totalActivities,
}: THeroDataProps): THeroImageData | undefined => {
    const cityName = destinationName.split(',')[0];

    switch (pathname) {
        case '/':
            return {
                images: [HomePageLight.src, HomePageDark.src],
                title: 'Discover. Explore. Experience.',
                subtitle: (
                    <SubtitleH1>
                        Reserve with TripShock & Save on Top Rated Tours & Activities
                    </SubtitleH1>
                ),
                scrollY: 637,
            };
        case '/blog':
            return {
                images: [Blog.src, Blog.src],
                title: 'Travel Articles',
                subtitle: 'Helpful Articles for Conscious Travelers',
                scrollY: 375,
            };
        case '/partners':
            return {
                images: [Partners.src, Partners.src],
                subtitle: 'Experience the Best',
                title: 'TripShock Partners',
                scrollY: 375,
            };
        case '/partners/[state]/[destination]':
            return {
                images,
                subtitle: `Book top rated experiences from the best tour, attraction, and rental operators`,
                title: `${cityName} Tour Operators`,
                scrollY: 375,
            };
        case '/destination/[state]/[destination]':
            return {
                images,
                subtitle: `Book ${totalActivities} Tour, Rentals & Attractions Tickets`,
                title: destinationName,
                scrollY: 375,
            };

        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case '/destination/[state]':
            return {
                images,
                subtitle: `Book ${totalActivities} Tour, Rentals & Attractions Tickets`,
                title: destinationName,
                scrollY: 375,
            };
        case '/blog/[state]/[...slug]':
            return {
                images,
                title: destinationName,
                subtitle: 'Travel Articles',
                scrollY: 375,
            };
        case '/destination/[state]/[destination]/landmarks/[slug]':
            return {
                images,
                title: destinationName,
                subtitle: subTitle,
                scrollY: 375,
            };
        case '/destination/[state]/[destination]/landmarks':
            return {
                images,
                title: destinationName,
                subtitle: 'Landmarks',
                scrollY: 375,
            };
        case '/landmarks':
            return {
                images: [Landmark.src, Landmark.src],
                title: 'Landmark Guides',
                subtitle: 'Incredible Places for You to Visit',
                scrollY: 375,
            };
        default:
            return;
    }
};
