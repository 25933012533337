export const LowPrice = () => {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.85954 4.2285C8.85954 3.92934 8.61703 3.68683 8.31787 3.68683C8.01872 3.68683 7.7762 3.92934 7.7762 4.2285V4.75973C7.51435 4.74839 7.25263 4.78699 7.00505 4.87358C6.74853 4.96329 6.51222 5.10275 6.3097 5.28396C6.10717 5.46517 5.94239 5.68457 5.82481 5.92958C5.70724 6.1746 5.63917 6.4404 5.62451 6.71177L6.16539 6.74098L5.6247 6.77353C5.65755 7.31933 5.9055 7.82984 6.31418 8.19311C6.71869 8.55267 7.24799 8.73895 7.78811 8.71229H8.85537C8.86457 8.71229 8.87376 8.71206 8.88294 8.71159C9.14397 8.69829 9.39969 8.7886 9.59447 8.96288C9.78113 9.1299 9.89736 9.36108 9.92054 9.60967C9.89736 9.85827 9.78113 10.0894 9.59447 10.2565C9.39969 10.4307 9.14397 10.5211 8.88294 10.5078C8.87376 10.5073 8.86457 10.5071 8.85537 10.5071H8.34279C8.33453 10.5067 8.32622 10.5065 8.31787 10.5065C8.30952 10.5065 8.30121 10.5067 8.29295 10.5071H6.88021C6.58106 10.5071 6.33854 10.7496 6.33854 11.0487C6.33854 11.3479 6.58106 11.5904 6.88021 11.5904H7.7762V12.1204C7.7762 12.4195 8.01872 12.6621 8.31787 12.6621C8.61703 12.6621 8.85954 12.4195 8.85954 12.1204V11.5911C9.39475 11.6101 9.91711 11.4215 10.3168 11.0638C10.7244 10.6991 10.9716 10.1882 11.0044 9.6422L10.4637 9.60967L11.0044 9.57714C10.9716 9.03117 10.7244 8.52025 10.3168 8.15554C9.91289 7.79412 9.38373 7.60526 8.84262 7.62896H7.77374C7.76337 7.62896 7.75301 7.62926 7.74267 7.62985C7.48311 7.64476 7.22823 7.55614 7.03391 7.38342C6.84732 7.21756 6.73119 6.98715 6.70842 6.73931C6.71856 6.62103 6.75007 6.50547 6.80151 6.39828C6.85741 6.28179 6.93576 6.17747 7.03206 6.09131C7.12835 6.00514 7.24071 5.93884 7.36268 5.89618C7.48466 5.85352 7.61385 5.83536 7.74286 5.84272C7.75314 5.84331 7.76344 5.8436 7.77374 5.8436H9.7489C10.0481 5.8436 10.2906 5.60109 10.2906 5.30194C10.2906 5.00278 10.0481 4.76027 9.7489 4.76027H8.85954V4.2285Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.98737 2.46684C6.30421 1.70065 7.84799 1.42038 9.35011 1.6748C10.8522 1.92921 12.2176 2.70222 13.2087 3.8593C14.199 5.01549 14.753 6.48204 14.7744 8.00414V10.5669L13.6015 9.394C13.39 9.18247 13.047 9.18247 12.8355 9.394C12.624 9.60554 12.624 9.9485 12.8355 10.16L14.9235 12.2481C15.0253 12.3498 15.1633 12.4069 15.3071 12.4067C15.451 12.4066 15.5889 12.3492 15.6904 12.2472L17.769 10.1592C17.9801 9.94716 17.9793 9.60419 17.7673 9.39314C17.5553 9.18208 17.2123 9.18286 17.0013 9.39487L15.8577 10.5436V8.00038H15.8578L15.8577 7.99305C15.8336 6.21626 15.1874 4.50414 14.0315 3.15457C12.8755 1.805 11.283 0.903415 9.53102 0.606676C7.77903 0.309936 5.97844 0.636827 4.44255 1.53047C2.90667 2.42412 1.7327 3.82795 1.1249 5.49772C0.517104 7.16749 0.513954 8.99751 1.116 10.6694C1.71804 12.3412 2.88718 13.7491 4.41998 14.648C5.95278 15.5469 7.75223 15.88 9.50524 15.5893C11.2582 15.2986 12.8539 14.4025 14.0144 13.0569C14.2098 12.8304 14.1846 12.4884 13.958 12.293C13.7315 12.0976 13.3895 12.1228 13.1941 12.3494C12.199 13.503 10.831 14.2713 9.328 14.5206C7.82501 14.7698 6.28221 14.4842 4.96802 13.7135C3.65383 12.9428 2.65144 11.7357 2.13526 10.3023C1.61908 8.86891 1.62178 7.2999 2.14289 5.86827C2.664 4.43665 3.67054 3.23303 4.98737 2.46684Z"
            />
        </svg>
    );
};
